#main-content {
  min-height: 100vh;
  padding-bottom: 50px;
}
/* ------------------------------------------ */
#navbar {
  position: sticky;
  top: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

/* make input invisiable */
#navbar-toggler {
  display: none;
}

#navbar-hamburger {
  font-size: 2em;
}

#navbar-toggler ~ div {
  display: none;
}

#navbar-toggler:checked ~ div {
  display: none;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(100, 100, 100, 0.5);
  z-index: 99 !important;
}

#navbar-list div {
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: 40vw;
  height: 100vh;
}

#navbar-list ul {
  padding: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 2em;
  top: 0px;
  right: 0px;
  width: 60vw;
  height: 100vh;
  background-color: rgb(46, 46, 46);
}

/* ---------------------------------------- */
#about-us-block {
  min-height: 300px;
  margin: 10px auto 10px auto;
  /* padding: 5px 5px 5px 5px; */
  width: 80%;
  flex-direction: column;
}

#about-us-map {
  flex: 1 1 auto;
}

#about-us-map > iframe {
  width: 100%;
}

#about-us-address {
  flex: 1 1 auto;
  font-size: clamp(15px, 2vw, 2vw);
}

#about-us-icons {
  flex: 0 1 auto;
  width: 100%;
}

#pirce-block {
  width: 80%;
}

#footer {
  height: 50px;
  margin-top: -50px;
}

#price-block {
  margin: 10px auto 10px auto;
  padding: 5px 5px 5px 5px;
  width: 80%;
  flex-direction: column;
}

#price-intro {
  flex: 1 1 auto;
  margin: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#price-detail {
  flex: 1 1 auto;
  margin: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#price-intro-show-block {
  background-color: var(--component-bg-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px;
  width: 100%;
  min-height: 150px;
  flex: 0 1 auto;
}

#price-intro-title {
  width: 100%;
  margin-bottom: 10%;
  font-size: clamp(20px, 2vw, 2vw);
  white-space: nowrap;
}

#price-computer {
  width: clamp(0px, 80%, 400px);
  margin: 10px auto;
}

#price-computer > div {
  display: flex;
  margin: 10px;
  padding: 10px;
  height: auto;
  flex-direction: column;
  flex: 0 1 auto;
  background: #ffffff;
  border: 1px solid #9e9e9e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

#price-computer > div > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#gold-show-section {
  flex: 1 1 auto;
}

#gold-future-price {
  font-size: clamp(20px, 3vw, 3vw);
}

#gold-futures-price {
  color: black;
  font-size: clamp(20px, 5vw, 5vw);
}

#price-detail-info {
  width: 100%;
  margin: 10px auto;
}
