/* starter reset */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  border: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* rem */
html {
  box-sizing: border-box;
  font-size: 16px;
  --theme-color: #1E1E1E;
  --component-bg-color: rgba(14, 92, 3, 0.288);
  --gold-color: rgb(0, 123, 255);
}

li {
  list-style-type: none;
}

button:hover {
  cursor: pointer;
}

a{
  text-decoration: none;
  color: inherit;
}

a:hover {
  cursor: pointer;
}
