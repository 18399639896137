@media only screen and (min-width: 780px) {
  #navbar-hamburger {
    display: none;
  }

  #navbar-toggler ~ div {
    display: block;
    position: static;
    width: auto;
    height: auto;
    background-color: transparent;
  }

  #navbar-toggler:checked ~ div {
    display: block;
    position: static;
    width: auto;
    height: auto;
    background-color: transparent;
  }

  #navbar-list div {
    display: none;
  }

  #navbar-list ul {
    padding: inherit;
    display: flex;
    flex-direction: row;
    position: static;
    width: auto;
    height: auto;
    font-size: inherit;
    gap: 10px;
    background-color: transparent;
  }

  .flickity-prev-next-button {
    display: block;
  }

  #price-block {
    flex-direction: row;
  }

  #about-us-block {
    flex-direction: row;
    height: 500px;
  }

  #about-us-icons {
    flex-direction: column;
  }

  #about-us-block {
    height: 300px;
  }

  #about-us-map {
    width: 250px;
  }

  .about-us-icon img {
    width: 50px;
    height: 50px;
  }

  #about-us-address {
    width: 250px;
  }

  #about-us-icons {
    width: 50px;
    height: 80%;
  }

  #price-intro {
    height: 300px;
  }

  #price-intro-show-block {
    padding: 20px;
    min-height: 200px;
  }

  #price-intro-title {
    margin-bottom: 1%;
    font-size: clamp(24px, 2.5vw, 36px);
    white-space: nowrap;
  }

  .gold-future-price-text {
    font-size: clamp(20px, 1.5vw, 36px);
  }

  #price-computer {
    width: clamp(350px, 40%, 400px);
    margin: 10px auto;
  }
}
